import { ClientFormUserRole, ClientFormUserRoleValues } from '../models/ClientFormUserRoles';
import { ClientFormUser } from '../models/ClientFormUser';
import { Roles } from '../models/Role';
import User from '../models/User';
import { hasPermission } from '../hooks/permissions/usePermissions';

const hasFormRole = (roles: ClientFormUserRoleValues | ClientFormUserRoleValues[], currentFormUser: ClientFormUser | null): boolean => {
  if (!Array.isArray(roles)) {
    roles = [roles];
  }
  return roles.find((role) => role === currentFormUser?.role) != null;
};

export const canCurrentUserSubmitForm = (currentFormUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  return hasFormRole([ClientFormUserRole.Owner, ClientFormUserRole.Contributor], currentFormUser);
};

export const canCurrentUserValidateForm = (currentFormUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  return hasFormRole([ClientFormUserRole.Validator], currentFormUser);
};

export const canCurrentUserApproveForm = (currentFormUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }

  return hasFormRole(ClientFormUserRole.Approver, currentFormUser);
};

export const canCurrentUserReopenForm = (currentFormUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  return hasFormRole([ClientFormUserRole.Owner, ClientFormUserRole.Contributor], currentFormUser);
};

export const canCurrentUserSubmitSection = (currentFormSectionUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  return hasFormRole([ClientFormUserRole.Owner, ClientFormUserRole.Contributor], currentFormSectionUser);
};

export const canCurrentUserApproveSection = (currentFormSectionUser: ClientFormUser | null, clientId: string, currentUser: User | null): boolean => {
  if (hasPermission(clientId, currentUser, [Roles.TeamMember])) {
    return true;
  }
  return hasFormRole([ClientFormUserRole.Owner, ClientFormUserRole.Approver], currentFormSectionUser);
};

export default hasFormRole;
